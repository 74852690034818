import React from "react"
import { Link as GatsbyLink } from 'gatsby'
import Helmet from 'react-helmet'
import {
    ThemeProvider,
    CSSReset,
    Box,
    Button,
    Divider,
    Heading,
    Link,
    Flex,
    Stack,
    Text
} from '@chakra-ui/react'
import theme2 from "../theme/";

import Seo from '../components/Seo/';


const error404Page = () => (
    // <LayoutAccount lang={process.env.GATSBY_LANG}>
    <ThemeProvider theme={theme2}>
        <CSSReset />
        <Box
            bg='gray.100'
            borderLeft='solid 10px'
            borderColor='blue.brand'
            minH='100vh'
        >
            <Seo
                title={
                    '404 Erreur / 404 Error : page'
                }
                slug={''}
            />
            <Helmet>
                <meta name="robots" content="index, nofollow" />
            </Helmet>
            <Flex
                justify='center'
                alignItems='center'
                h='100vh'
            >
                <Box
                    maxW='650px'
                    // h='400px'
                    bg='white'
                    p={10}
                    minH={{ base: '100vh', lg: 'auto' }}
                >
                    <Stack spacing={6}>
                        <Heading color="blue.brand">
                            404 page
                        </Heading>
                        <Text>
                            Aïe Capitaine, cette page n'existe pas, n'existe plus, en réalité, on ne sait pas tellement, on va pas se mentir. Ce qu'on peut vous proposer de mieux c'est de
                            {' '}<Link as={GatsbyLink} to='../' textDecor='underline'>retourner à la page d'accueil.</Link>
                        </Text>
                        <Divider />
                        <Text>
                            Ouch Captain, this page doesn't exist, doesn't exist anymore, in fact, we don't know so much, let's not lie to ourselves. The best we can offer you is to
                            {' '}<Link as={GatsbyLink} to='../' textDecor='underline'>go back to the homepage.</Link>
                        </Text>
                        <Divider />
                        <Stack isInline>
                            <Button whiteSpace='pre' as={GatsbyLink} to='../'>🇫🇷{' '} Aller à l'accueil</Button>
                            <Button whiteSpace='pre' as={GatsbyLink} to='../'>🇺🇸{' '} Go to homepage</Button>
                        </Stack>

                    </Stack>
                </Box>
            </Flex>
        </Box>
    </ThemeProvider>

)

export default error404Page